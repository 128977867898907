import React from "react"
import { AsideLayout } from "components/aside-layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Service } from "components/service"
import { FollowBtn } from "components/follow-btn"
import { DefaultHeader } from "../../../components/headers/default-header"

const CouponCampaign = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="en-US" />
      <AsideLayout lang="en-US">
        <Seo
          pagetitle="Web coupon distribution through Twitter Instant Win campaign"
          pagepath={location.pathname}
          pagedesc="Coupon distribution through a Twitter campaign can aim at both spreading the word and attracting customers to your e-commerce and storefront. For those in charge who want to call for store visits at a reasonable price and without hassle, we will explain the campaign flow, advantages of implementing the tool, its rich functions, and fees."
        />
        <main className="coupon">
          <section className="fv pt-8 px-4 md:px-11 md:pt-14 relative">
            <div>
              <div className="flex gap-3 mb-3">
                <p className="bg-coup_sec text-center py-2 px-3 font-bold rounded-lg text-coupon md:text-2xl">
                  Significant reduction in printing costs
                </p>
                <p className="bg-coup_sec text-center py-2 px-3 font-bold rounded-lg text-coupon md:text-2xl">
                  Automatic drawing
                </p>
              </div>
              <h1 className="font-bold text-white mb-3 md:mb-5">
                Coupon distribution
                <br />
                on SNS to promote
                <br />
                visits to physical
                <br />
                stores and EC!
              </h1>
              <p className="font-bold text-white leading-6 md:text-xl md:leading-8 fv-lead">
                Distribute easy-to-manage
                <br />
                web coupons through
                <br />
                Twitter campaigns.
                <br />
                You can aim to both spread
                <br />
                the effect of social networking
                <br />
                and attract more customers!
              </p>
            </div>
            <div className="md:absolute w-9/12 md:w-6/12 md:right-0 md:bottom-0 ml-auto">
              <StaticImage
                src="../../../images/product/coupon/top_coupon.png"
                alt="Easy-to-manage web coupons can be distributed through Twitter campaigns, aiming to both spread the word and attract more customers through SNS!"
              />
            </div>
          </section>
          <section className="sec01 bg-white">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="flex flex-col-reverse md:flex-row md:gap-8">
                <div className="md:w-2/5">
                  <StaticImage
                    src="../../../images/product/coupon/sec01.png"
                    alt="Easier to create coupon campaigns that deliver results"
                  />
                </div>
                <div className="md:w-3/5">
                  <h2 className="text-coup_title text-3xl md:text-4xl font-bold mb-4">
                    Easier to create
                    <br />
                    coupon campaigns
                    <br />
                    that deliver results
                  </h2>
                  <p className="text-serv_text">
                    We recommend this service for those who want to lead
                    customers from social networking sites to actual stores and
                    who have trouble distributing paper coupons because of the
                    time and effort required. We can help you create and manage
                    easy-to-use Web coupons and spread them efficiently on SNS
                    to encourage customers to visit your store.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="flow">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Flow</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">
                  Coupon Distribution Campaign Flow
                </p>
              </div>
              <div className="flex md:items-stretch md:gap-16 flex-col md:flex-row gap-y-10 items-center">
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="Follow us on Twitter and retweet to participate in the campaign"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step01.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    Follow us on Twitter and retweet to participate in the
                    campaign
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="Notify coupon URL via DM"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step02.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    Notify coupon URL via DM
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 card-arrow w-full">
                  <div className="p-3 text-center">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="Receive and redeem coupons"
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step03.png"
                      width={130}
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    Receive and redeem coupons
                  </p>
                </div>
                <div className="rounded-lg bg-white p-4 w-full">
                  <div className="px-8 md:px-0">
                    <img
                      layout="constrained"
                      style={{ display: "inline" }}
                      alt="Increase awareness and attract more customers through coupon use."
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/step04.png"
                      className="mb-8"
                    />
                  </div>
                  <p className="font-bold text-serv_text">
                    Increase awareness and attract more customers through coupon
                    use
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="merit">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Merit</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">
                  Advantages of using PARKLoT
                </p>
              </div>
              <div className="mb-8">
                <h3 className="merit-subtitle text-serv_text text-xl font-bold mb-2 before:content-['01']">
                  Unlimited coupons issued on a monthly basis
                </h3>
                <p className="text-serv_text">
                  Starting at 55,000 yen per month (tax included), you can hold
                  as many campaigns as you wish at a reasonable price.
                  <br />
                  Two types of coupons allow for more attractive plan design.
                  Printing costs for paper coupons can also be reduced.
                </p>
              </div>
              <div className="flex flex-col md:flex-row md:justify-between gap-4 md:gap-8 mb-20">
                <div className="bg-white bg-opacity-40 p-4 rounded-lg w-full">
                  <h4 className="bg-coupon text-center font-bold text-white px-3 py-1 mb-3 rounded">
                    Erasable coupon
                  </h4>
                  <div className="text-center mb-3">
                    <img
                      style={{ display: "inline" }}
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/merit01.png"
                      alt="erasable coupon"
                      width={228}
                    />
                  </div>
                  <p className="text-serv_text">
                    Within a certain amount of time after the coupon is
                    displayed, staff at the store tap a button on the screen to
                    confirm use, thereby preventing multiple uses of the coupon.
                    The coupon will be automatically used after a certain period
                    of time has elapsed.
                  </p>
                </div>
                <div className="bg-white bg-opacity-40 p-4 rounded-lg w-full">
                  <h4 className="bg-coupon text-center font-bold text-white px-3 py-1 mb-3 rounded">
                    Unlimited during the term
                  </h4>
                  <div className="text-center mb-3">
                    <img
                      style={{ display: "inline" }}
                      src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/coupon/merit02.png"
                      alt="Unlimited during the term"
                      width={228}
                    />
                  </div>
                  <p className="text-serv_text">
                    Can be used as many times as needed during the designated
                    period to promote continued use
                  </p>
                </div>
              </div>
              <div className="mb-8">
                <h3 className="merit-subtitle merit-subtitle__2line text-serv_text text-xl font-bold mb-2 before:content-['02']">
                  Post tweets, draw lots, send DMs, and distribute coupons all
                  in one place, hassle-free{" "}
                </h3>
                <p className="text-serv_text">
                  Just set it up from the PARKLoT management screen, and the
                  campaign will start automatically on time. All the tedious
                  work of drawing lots and sending out coupons can also be
                  automated, making it very efficient.
                  <br />
                  Repeated events can be held to further attract customers.
                </p>
              </div>
            </div>
          </section>
          <section className="feature">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Feature</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">
                  Feature
                </p>
              </div>
              <div className="flex flex-wrap gap-4">
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/coupon.png"
                        className="md:w-20 w-14"
                        alt="Coupon Creation"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      Coupon Creation
                    </h3>
                    <p className="text-serv_text">
                      You can create three types of coupons: erasable, timed,
                      and unlimited time coupons, which can also display a QR
                      code.
                      <br />
                      <span className="font-bold text-coupon">
                        Coupon screen display image
                      </span>
                      <br />
                      <span className="font-bold text-coupon">
                        Simply share the coupon code/QR code/external URL
                      </span>
                      <br />
                      to complete the setup.
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/lottery.png"
                        className="md:w-20 w-14"
                        alt="Automatic drawing"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      Automatic drawing
                    </h3>
                    <p className="text-serv_text">
                      The coupon type and number of winners can be set in
                      advance, and users who follow and retweet the coupon will
                      be automatically selected by lottery; the number of
                      winners can be limited from one to all participants, and
                      the winning conditions can be filtered for more efficient
                      spread.
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/email.png"
                        className="md:w-20 w-14"
                        alt="Auto-forward"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      Auto-forward
                    </h3>
                    <p className="text-serv_text">
                      After the automatic lottery, DMs can be sent to the
                      winners automatically. Instant winners know the results on
                      the spot, making it harder for applicants to leave the
                      site and easier to direct them to the e-commerce site or
                      actual store.
                      <br />
                      <span className="font-bold text-coupon">
                        19% recovery rate
                      </span>
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/reserve.png"
                        className="md:w-20 w-14"
                        alt="Tweet Reserved Submission"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      Tweet Reserved Submission
                    </h3>
                    <p className="text-serv_text">
                      After setting various data from the management screen,
                      tweets are automatically posted at the specified time.
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/csv.png"
                        className="md:w-20 w-14"
                        alt="Applicant list CSV data"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      Applicant list CSV data
                    </h3>
                    <p className="text-serv_text">
                      We can provide CSV data on applicants who followed the
                      company account and retweeted the corresponding tweet.
                      This is an effective way to look back and plan for the
                      next time, as you can also check the usage status of
                      coupons that have already been issued.{" "}
                    </p>
                  </div>
                </div>
                <div className="bg-white card rounded relative before:absolute before:w-full before:h-3 before:bg-coupon before:rounded-t">
                  <div className="p-4 pt-6 md:p-6 md:pt-7">
                    <div className="text-center mb-4">
                      <StaticImage
                        src="../../../images/product/follower.png"
                        className="md:w-20 w-14"
                        alt="Follower count management"
                      />
                    </div>
                    <h3 className="text-center font-bold text-serv_text mb-3">
                      Follower count management
                    </h3>
                    <p className="text-serv_text">
                      Followers can be viewed in chart form from the management
                      screen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="price bg-coup_sec">
            <div className="py-10 px-4 md:pt-14 md:pb-20 md:px-10">
              <div className="text-center mb-8">
                <h2 className="text-coup_title hs__title">Price</h2>
                <p className="text-serv_subt font-bold text-xl md:pb-6">
                  Price
                </p>
              </div>
              <div className="flex flex-col gap-4 md:gap-10 md:flex-row">
                <div className="bg-white rounded-lg w-full">
                  <h3 className="bg-coupon text-center rounded-t-lg py-2 font-bold text-white text-xl">
                    Basic Charge
                  </h3>
                  <div className="p-4 md:p-6">
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        Initial cost
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">80,000</span>{" "}
                        yen~
                      </p>
                    </div>
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        Monthly Amount
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">50,000</span>yen~
                      </p>
                    </div>
                    <div className="mb-5">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        Total amount
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coupon text-3xl">130,000</span>
                        yen~(143,000 yen~ including tax)
                      </p>
                    </div>
                    <p className="text-xs text-serv_subt">
                      *Hashtag collection and gift code giveaway campaigns can
                      also be implemented.
                    </p>
                  </div>
                </div>
                <div className="bg-white rounded-lg w-full">
                  <h3 className="bg-serv_subt text-center rounded-t-lg py-2 font-bold text-white text-xl">
                    Options
                  </h3>
                  <div className="p-4 md:p-6">
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        Collecting Reply
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">30,000</span>
                        yen~
                      </p>
                    </div>
                    <div className="mb-3">
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        Collecting Likes
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">30,000</span>
                        yen~
                      </p>
                    </div>
                    <div>
                      <h4 className="bg-serv_lightgrey p-1 text-center text-serv_text mb-1">
                        Campaign LP creation agency
                      </h4>
                      <p className="text-center font-bold">
                        <span className="text-coup_title text-3xl">
                          100,000
                        </span>
                        yen~
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <Service />
        </main>
      </AsideLayout>
      <FollowBtn />
    </>
  )
}

export default CouponCampaign
